import React from 'react'
import Layout from "../layout/defaultLayout";
import Container from '../components/layout/Container';

import styled from 'styled-components'

const StyledIframeContainer = styled.div`
    overflow: hidden;
    padding-top: 56.25%;
    position: relative;
    iframe {
      border: 0;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      /* padding: 20px; */
    }
`

const kalender = () => (
  <Layout>
    <Container>
    <StyledIframeContainer>
      <iframe
        src="https://vblweb.wisseq.eu/Home/ClubDetail?guid=BVBL1189"
        id="responsiveIframe"
        title='vbl iframe'
        frameBorder="0"
        kwframeid="2"
      />
    </StyledIframeContainer>
    </Container>
  </Layout>
)

export default kalender